<template>
  <span :class="['zspinner', formattedSize, formattedVariant]" />
</template>

<script setup lang="ts">
type Size = 'sm' | 'md'
type Variant = 'primary' | 'highlight'

const props = withDefaults(defineProps<{
  size?: Size
  variant?: Variant
}>(), {
  size: 'md',
  variant: 'primary',
})

const formattedSize = computed(() => {
  return `zspinner-${props.size}`
})

const formattedVariant = computed(() => {
  return `zspinner-${props.variant}`
})
</script>
